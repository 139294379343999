/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

/**
 * Get token from meta tag in header and add CSRF token to the ajax request
 * 
 * @author Rick van der Burg <rick@click.nl>
 */
function prepareForAjax() {
    $.ajaxSetup(
        {
            headers:
            {
                'X-CSRF-Token': $('meta[name="_token"]').prop('content')
            }
        });
}

prepareForAjax();